import React, { Component } from 'react'
import { Row, Col, Card, CardBody, Alert } from 'reactstrap'

// Redux
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation'

import Loader from '../../components/Loader'
// actions
import { settingNewPassword } from '../../store/actions'

// import images
import logoSm from '../../assets/images/svartPNG.png'

class ForcePasswordChange extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this)
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    this.props.settingNewPassword(
      this.props.user,
      values.email,
      values.newPassword,
      this.props.history
    )
  }

  render() {
    return (
      <React.Fragment>
        <div className='home-btn d-none d-sm-block'>
          <Link to='/' className='text-dark'>
            <i className='fas fa-home h2'></i>
          </Link>
        </div>
        <div className='account-pages my-5 pt-5'>
          <div className='container'>
            <Row className='justify-content-center'>
              <Col md={8} lg={6} xl={5}>
                <div className='position-relative'>
                  {this.props.loading ? <Loader /> : null}

                  <Card className='overflow-hidden'>
                    <div className='bg-primary'>
                      <div className='text-primary text-center p-4'>
                        <h5 className='text-white font-size-20'>
                          Sätta lösenord
                        </h5>
                        <p className='text-white-50'>
                          Sätt ditt lösenord för att signa in.
                        </p>
                        <Link to='/' className='logo logo-admin'>
                          <img src={logoSm} height='24' alt='logo' />
                        </Link>
                      </div>
                    </div>

                    <CardBody className='p-4'>
                      <div className='p-3'>
                        <AvForm
                          className='form-horizontal mt-4'
                          onValidSubmit={this.handleValidSubmit}
                        >
                          {this.props.error ? (
                            <Alert color='danger'>{this.props.error}</Alert>
                          ) : null}

                          <div className='form-group'>
                            <AvField
                              name='email'
                              label='Email'
                              value=''
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: 'Inte giltig email',
                                },
                                email: {
                                  value: true,
                                  errorMessage: 'Inte giltig email',
                                },
                              }}
                              placeholder='Email'
                            />
                          </div>
                          <div className='form-group'>
                            <AvField
                              name='newPassword'
                              label='Nytt lösenord'
                              type='password'
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: 'Inte giltigt lösenord',
                                },
                                pattern: {
                                  // ^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]
                                  // (?=.*[a-z])(?=.*[A-Z])(?=.*)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]
                                  value:
                                    '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[?!&%\\[\\]@{}^])',
                                  errorMessage:
                                    'Ditt lösenord måste ha stora och små bokstäver minst en symbol och nummer',
                                },
                              }}
                              value=''
                              placeholder='Nytt lösenord'
                            />
                          </div>

                          <Row className='form-group'>
                            <Col sm={6}>
                              &nbsp;
                              {/* <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input" id="customControlInline" />
                                                            <label className="custom-control-label" for="customControlInline">Remember me</label>
                                                        </div> */}
                            </Col>
                            <Col sm={6} className='text-right'>
                              <button
                                className='btn btn-primary w-md waves-effect waves-light'
                                type='submit'
                              >
                                Sätt lösenord
                              </button>
                            </Col>
                          </Row>
                        </AvForm>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStatetoProps = (state) => {
  const { error, user, loading } = state.Login
  return { error, user, loading }
}

export default withRouter(
  connect(mapStatetoProps, { settingNewPassword })(ForcePasswordChange)
)
