import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { map, reduce } from "lodash-es";
import { googleSilverTheme } from "../../../../theme/google";
import { PURPLE, GREEN } from "../../../../theme/colors";
import { formatPharmacy, getBrandknowledgeKey } from "../../../../utils";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import PersonIcon from "@material-ui/icons/Person";
import { Map, Marker } from "google-maps-react";

import HomeIcon from "@material-ui/icons/Home";
import { CircularProgress } from "@material-ui/core";

const StyledHomeIcon = styled(HomeIcon)`
  && {
    width: 1.3em;
    height: 1.4em;
  }
`;
const StyledGroupIcon = styled(PersonIcon)`
  && {
    width: 1.3em;
    height: 1.4em;
  }
`;

const MapInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const MapInfoContent = styled.div`
  margin: 1rem;
  text-align: center;
`;
const MapKpi = styled.div`
  border-bottom: 2px solid ${GREEN};
  display: flex;
  justify-content: center;
  text-align: center;
`;

const KPINumber = styled.h2`
  margin-top: 2px;
  margin-bottom: 0.2rem;
`;

export const MeetingsMapColumn = ({
  google,
  meetingsFilter,
  loadingVisitor,
  filteredData,
  initialCenter,
  isVet
}) => {
  const { t } = useTranslation();
  return (
    <Col xl={3} md={3} style={{ display: "flex", flexDirection: "column" }}>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <MapInfoWrapper>
                <MapInfoContent>
                  <MapKpi>
                    {loadingVisitor ? (
                      <KPINumber>
                        <CircularProgress size={34} style={{ color: GREEN }} />
                      </KPINumber>
                    ) : (
                      <KPINumber>{filteredData.length}</KPINumber>
                    )}
                    <StyledHomeIcon style={{ fill: PURPLE }} />
                  </MapKpi>
                  <h4
                    style={{
                      marginTop: "0.3rem",
                    }}
                    className="header-title mb-4"
                  >
                    {t("dashboard.number_of_visits")}
                  </h4>
                </MapInfoContent>
                <MapInfoContent>
                  <MapKpi>
                    {loadingVisitor ? (
                      <KPINumber>
                        <CircularProgress size={34} style={{ color: GREEN }} />
                      </KPINumber>
                    ) : (
                      <KPINumber>
                        {reduce(
                          filteredData,
                          (a, b) => {
                            return a + b.numberOfParticipants;
                          },
                          0
                        )}
                      </KPINumber>
                    )}
                    <StyledGroupIcon style={{ fill: PURPLE }} />
                  </MapKpi>
                  <h4
                    className="header-title mb-4"
                    style={{ marginTop: "0.3rem" }}
                  >
                    {t("dashboard.number_of_staff")}
                  </h4>
                </MapInfoContent>
              </MapInfoWrapper>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div
                id="gmaps-markers"
                className="gmaps"
                style={{ position: "relative" }}
              >
                <Map
                  mapTypeControl={false}
                  streetViewControl={false}
                  mapTypeControlOptions={false}
                  mapTypeIds={["roadmap"]}
                  google={google}
                  styles={googleSilverTheme}
                  initialCenter={initialCenter}
                  center={initialCenter}
                  zoom={meetingsFilter.city.length > 1 ? 4 : 10}
                  style={{ width: "100%", height: "100%" }}
                >
                  {filteredData
                    .map((item) => {
                      if (item.pharmacyLocation) {
                        const { lat, lon } = item.pharmacyLocation;
                        return { lat: lat, lng: lon };
                      }
                      return { lat: null, lng: null };
                    })
                    .map((postition, index) => (
                      <Marker key={`${index}_marker`} position={postition} />
                    ))}
                </Map>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row style={{ flex: 1, marginBottom: 24 }}>
        <Col>
          <Card style={{ height: "100%" }}>
            <CardBody>
              <h4 className="mt-0 header-title mb-4">
                {t("dashboard.meeting_log")}
              </h4>
              <ol className="activity-feed">
                {map(
                  filteredData.sort((a, b) =>
                    a.meetingDate < b.meetingDate ? 1 : -1
                  ),
                  (data, index) => (
                    <li key={`meeting_item_${index}`} className="feed-item">
                      <div className="feed-item-list">
                        <span className="date">
                          {new Date(data.meetingDate).toLocaleDateString(
                            "se-SE"
                          )}
                        </span>
                        <span className="info" style={{ color: GREEN }}>
                          {`${formatPharmacy(data.pharmacyName)}`}
                        </span>

                        <span className="info">
                          {meetingsFilter?.isRxProduct
                            ? t(
                                `brand_knowledge.${getBrandknowledgeKey(
                                  data.brandKnowledgeRX,
                                  isVet
                                )}`
                              )
                            : t(`brand_knowledge.${data.brandKnowledge}`)}
                        </span>
                        <span className="activity-text">
                          {data.meetingNotes}
                        </span>
                      </div>
                    </li>
                  )
                )}
              </ol>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Col>
  );
};
