import React from "react";
import ReactEcharts from "echarts-for-react";
import { filter, map } from "lodash-es";
import { formatProductPlacement, generateDataPoint } from "../utils";
import {
  DARKER_GREY,
  GREEN,
  WHINE_RED,
  PURPLE,
  LIGHTER_WHINE_RED,
} from "../theme/colors";
import { useTranslation } from "react-i18next";

const ListingChart = ({ data, isRx, vetView, customerName }) => {
  const { t } = useTranslation();
  const getOTCListing = () => {
    const base = ["shelf", "behindRegister", "onDemand"];
    if (customerName === "Vetoquinol" && vetView) {
      return [...base, "no_store", "other"];
    }
    return [...base, "other"];
  };

  const getListings = () => {
    if (isRx) {
      return ["rest", "on_order", "not_in_stock", "in_stock", "other"];
    }
    return getOTCListing();
  };
  const listings = getListings();

  const dataPoints = map(listings, (key) => {
    return generateDataPoint(
      data,
      isRx ? "warehouseStatus" : "productPlacement",
      key,
      (key) => t(`product_placement.${key}`)
    );
  });
  const getOption = () => {
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        icon: "roundRect",
        itemWidth: 13,
        itemHeight: 13,
        x: "right",
        data: listings.map((key) => t(`product_placement.${key}`)),
        textStyle: {
          color: ["#74788d"],
        },
      },
      color: [GREEN, PURPLE, WHINE_RED, LIGHTER_WHINE_RED, DARKER_GREY],
      series: [
        {
          name: t("dashboard.product_placement"),
          type: "pie",
          radius: "55%",
          center: ["50%", "65%"],
          data: dataPoints,
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };
  };

  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "350px" }} option={getOption()} />
    </React.Fragment>
  );
};
export default ListingChart;
