import React, { useEffect, useState, useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'

//AUTH related methods
import { getCurrentUser } from '../helpers/authCognito'
import { get } from 'lodash-es'
import LoadingPage from '../components/LoadingPage'
import { UserContext } from '../context'

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => {
  // const { user } = useContext(UserContext)
  const [user, setUser] = useState({
    validated: false,
    loading: true,
  })
  useEffect(() => {
    async function checkAuth() {
      try {
        getCurrentUser()
          .then((cognitoUser) => {
            setUser({
              username: get(cognitoUser, 'username'),
              region: get(cognitoUser, ['attributes', 'custom:region']),
              email: get(cognitoUser, ['attributes', 'email']),
              isUserLoaded: true,
              loading: false,
              validated: true,
              cognitoUser,
              session: cognitoUser ? cognitoUser.signInUserSession : null,
            })
          })
          .catch((e) => {
            console.log(e)
          })
      } catch (e) {
        console.log(e)
      }
    }
    checkAuth()
  }, [])

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthProtected && user.loading) return <LoadingPage />
        if (isAuthProtected && !user.session && !user.loading) {
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          )
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }}
    />
  )
}

export default AppRoute
