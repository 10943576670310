import React, { useEffect } from "react";
import { Row, Col, Card, CardBody, Alert } from "reactstrap";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Loader from "../../components/Loader";
import { loginUser } from "../../store/actions";
import logoSm from "../../assets/images/svartPNG.png";
import { useTranslation } from "react-i18next";

const Login = (props) => {
  const handleValidSubmit = (event, values) => {
    props.loginUser(values, props.history);
  };

  const { t, i18n } = useTranslation();
  // useEffect(() => {
  //   i18n.changeLanguage("en");
  // }, []);

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-5">
        <div className="container">
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div className="position-relative">
                {props.loading ? <Loader /> : null}

                <Card className="overflow-hidden">
                  <div className="bg-primary">
                    <div className="text-primary text-center p-4">
                      <h5 className="text-white font-size-20">
                        {t("login.welcome")}
                      </h5>
                      <p className="text-white-50">
                        {t("login.sign_in_to_continue")}.
                      </p>
                      <Link to="/" className="logo logo-admin">
                        <img
                          style={{
                            position: "relative",
                            left: "2px",
                            bottom: "2px",
                          }}
                          src={logoSm}
                          height="34"
                          alt="logo"
                        />
                      </Link>
                    </div>
                  </div>

                  <CardBody className="p-4">
                    <div className="p-3">
                      <AvForm
                        className="form-horizontal mt-4"
                        onValidSubmit={handleValidSubmit}
                      >
                        {props.error ? (
                          <Alert color="danger">{props.error}</Alert>
                        ) : null}

                        <div className="form-group">
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder={t("login.email")}
                            type="email"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Inte giltig email",
                              },
                              email: {
                                value: true,
                                errorMessage: "Inte giltig email",
                              },
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <AvField
                            name="password"
                            label={t("login.password")}
                            type="password"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Inte giltigt lösenord",
                              },
                            }}
                            placeholder="Lösenord"
                          />
                        </div>

                        <Row className="form-group">
                          <Col sm={6}>&nbsp;</Col>
                          <Col sm={6} className="text-right">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              {t("login.login")}
                            </button>
                          </Col>
                        </Row>
                        {/* <Row className='form-group mt-2 mb-0'>
                          <div className='col-12 mt-4'>
                            <Link to='/forget-password'>
                              <i className='mdi mdi-lock'></i> 
                              {t("login.forgot_password")}
                            </Link>
                          </div>
                        </Row> */}
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

// Keeping the Redux connection intact, as in the class component
const mapStateToProps = (state) => {
  const { error, loading } = state.Login;
  return { error, loading };
};

export default withRouter(connect(mapStateToProps, { loginUser })(Login));
