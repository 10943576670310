import { takeEvery, fork, put, all, call } from 'redux-saga/effects'

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SETTING_NEW_PASSWORD } from './actionTypes'
import {
  loginSuccess,
  forcePasswordChange,
  logoutUserSuccess,
  apiError,
} from './actions'
import { get } from 'lodash-es'

//AUTH related methods

import { postLogin, postNewPassword } from '../../../helpers/authCognito'

function* loginUser({ payload: { user, history } }) {
  try {
    // console.log('---------Login user')
    const response = yield call(postLogin, user.email, user.password)
    // console.log(
    //   'RESPONSE---------',
    //   response,
    //   get(response, 'challengeName', '')
    // )
    if (get(response, 'challengeName', '') === 'NEW_PASSWORD_REQUIRED') {
      yield put(forcePasswordChange(response))
      // console.log('pushuing')

      history.push('/new-password')
    } else {
      yield put(loginSuccess(response))
      history.push('/dashboard')
    }
  } catch (error) {
    console.log('errror', error)
    yield put(apiError(error.message))
  }
}

function* settingNewPassword({
  payload: { user, email, newPassword, history },
}) {
  try {
    // console.log('---------settingNewPassword user', user)
    const response = yield call(postNewPassword, user, email, newPassword)
    // console.log('Response to CHange', response)
    yield put(loginSuccess(response))
    history.push('/dashboard')
  } catch (error) {
    console.log('error', error)
    yield put(apiError(error.message))
  }
}

// function* logoutUser({ payload: { history } }) {
//   try {
//     const response = yield call(fireBaseBackend.logout)
//     yield put(logoutUserSuccess(response))
//     history.push('/login')
//   } catch (error) {
//     yield put(apiError(error))
//   }
// }

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser)
}

export function* watchUserForceChangePassword() {
  yield takeEvery(SETTING_NEW_PASSWORD, settingNewPassword)
}

// export function* watchUserLogout() {
//   yield takeEvery(LOGOUT_USER, logoutUser)
// }

function* authSaga() {
  yield all([
    fork(watchUserLogin),
    // fork(watchUserLogout),
    fork(watchUserForceChangePassword),
  ])
}

export default authSaga
