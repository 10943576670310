import React, { useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import TimePicker from "../../../../components/TimePicker";
import StyledSelect from "../../../../components/StyledSelect";
import {
  getCityValues,
  getPharmacyValues,
  formatCity,
  formatPharmacy,
} from "../../../../utils";
import { useTranslation } from "react-i18next";
import { Divider } from "@material-ui/core";
import styled from "@emotion/styled";
import { GREEN, PURPLE } from "../../../../theme/colors";
const SelectorWrapper = styled.div`
margin 1rem 0.5rem;
`;
const TimeWrapper = styled.div`
  display: flex;
  width: 100%;
`;

// Styling Components
const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HiddenCheckbox = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`;

const StyledLabel = styled.label`
  cursor: pointer;
  width: 50px;
  height: 25px;
  background: ${(props) => (props.isOn ? PURPLE : GREEN)};
  display: block;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.3s ease;
`;

const StyledButton = styled.span`
  position: absolute;
  top: 3px;
  left: ${(props) => (props.isOn ? "calc(100% - 3px)" : "3px")};
  width: 20px;
  height: 20px;
  border-radius: 90px;
  transition: 0.3s;
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transform: ${(props) => (props.isOn ? "translateX(-100%)" : "none")};
`;
const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const H5 = styled.h5`
  text-size: 1rem;
`;

// SwitchToggle Component
const SwitchToggle = ({ isOn, handleToggle }) => {
  return (
    <ToggleContainer onClick={handleToggle}>
      {/* Removed HiddenCheckbox since we are not using it */}
      <StyledLabel isOn={isOn}>
        <StyledButton isOn={isOn} />
      </StyledLabel>
    </ToggleContainer>
  );
};

// SwitchToggleView Component
const SwitchToggleView = ({ setFilter, pharmacyValues }) => {
  const [isOn, setIsOn] = useState(false);
  const { t } = useTranslation();

  const handleChange = () => {
    setIsOn((prev) => {
      setFilter((oldState) => {
        const newState = { ...oldState, vetView: !prev };

        if (!prev === true) {
          newState.pharmacy = ["veterinarian"];
        } else {
          newState.pharmacy = pharmacyValues;
        }

        return newState;
      });

      return !prev;
    });
  };

  return (
    <ToggleWrapper>
      <H5>{isOn ? t("vet") : t("pharamcy")}</H5>
      <SwitchToggle isOn={isOn} handleToggle={handleChange} />
    </ToggleWrapper>
  );
};

export const Filters = ({
  setFilter,
  meetingsFilter,
  customerName,
  products,
  rxProducts,
  startTime,
  endTime,
  handelSetStartTime,
  handelSetEndTime,
  isVetCustomer,
}) => {
  const { t } = useTranslation();
  const handleChange = (event, key) => {
    console.log(key, event.target.value);
    setFilter((oldState) => {
      const newState = { ...oldState };
      newState[key] = event.target.value;
      if (key === "product") {
        if (rxProducts.includes(event.target.value)) {
          newState["isRxProduct"] = true;
        } else {
          newState["isRxProduct"] = false;
        }
      }

      return newState;
    });
  };
  return (
    <Card style={{ marginTop: "1.5rem" }}>
      <CardBody>
        <Row>
          <Col md={3}>
            <SelectorWrapper>
              <TimeWrapper>
                <TimePicker
                  startDate={startTime}
                  endDate={endTime}
                  setStartDate={handelSetStartTime}
                  setEndDate={handelSetEndTime}
                />
              </TimeWrapper>
              <Divider orientation="vertical" flexItem />
            </SelectorWrapper>
          </Col>
          <Col md={2}>
            <SelectorWrapper>
              <StyledSelect
                single={true}
                values={products}
                label={t("dashboard.product")}
                name="produkt"
                selected={meetingsFilter.product}
                onChange={(event) => handleChange(event, "product")}
              />
            </SelectorWrapper>
          </Col>
          <Col md={2}>
            <SelectorWrapper>
              <StyledSelect
                values={getCityValues(customerName)}
                label={t("dashboard.region")}
                formatFunction={formatCity}
                name="stad"
                selected={meetingsFilter.city}
                onChange={(event) => handleChange(event, "city")}
              />
            </SelectorWrapper>
          </Col>
          {!meetingsFilter.vetView && (
            <Col md={2}>
              <SelectorWrapper>
                <StyledSelect
                  values={getPharmacyValues(customerName)}
                  label={t("dashboard.chain")}
                  name="kedja"
                  formatFunction={formatPharmacy}
                  selected={meetingsFilter.pharmacy}
                  onChange={(event) => handleChange(event, "pharmacy")}
                />
              </SelectorWrapper>
            </Col>
          )}
          <Col md={2}>
            <SelectorWrapper>
              <StyledSelect
                values={["1", "2", "3", "4"]}
                label={t("dashboard.meeting")}
                name="möte"
                formatFunction={(nmr) => t(`meeting.${nmr}`)}
                selected={meetingsFilter.meetingNr}
                onChange={(event) => handleChange(event, "meetingNr")}
              />
            </SelectorWrapper>
          </Col>
          {isVetCustomer && (
            <>
              {meetingsFilter.vetView && <Col md={2} />}
              <Col md={1}>
                <SelectorWrapper>
                  <SwitchToggleView
                    setFilter={setFilter}
                    pharmacyValues={getPharmacyValues(customerName)}
                  />
                </SelectorWrapper>
              </Col>
            </>
          )}
        </Row>
      </CardBody>
    </Card>
  );
};
