import React, { useEffect, memo } from 'react'
import styled from '@emotion/styled'
import CircularProgress from '@material-ui/core/CircularProgress'
import { GREEN } from '../theme/colors'

const SpinnerWrapper = styled.div`
  width: 100%;
  height: ${(p) => p.height || 'calc(100vh - 64px)'};
  display: flex;
  justify-content: center;
  align-items: center;
`

export default memo((props) => {
  useEffect(() => window.scrollTo(0, 0), [])

  return (
    <SpinnerWrapper {...props}>
      <CircularProgress style={{ color: GREEN }} />
    </SpinnerWrapper>
  )
})
