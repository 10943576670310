import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Importing your translation files
import en from './locales/en/translation.json';
import sv from './locales/sv/translation.json';
import se from './locales/se/translation.json';

i18n
  .use(LanguageDetector) // Automatically detects the user's language
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: en, // English translations
      },
      sv: {
        translation: sv, // Swedish translations
      },
      se: {
        translation: se, // Another language (Sámi?) translations
      },
    },
    fallbackLng: 'en', // Default language if the user's language is not available
    debug: true,
    interpolation: {
      escapeValue: false, // React already does escaping, so no need for this
    },
  });

i18n.changeLanguage('sv'); // Force Swedish

export default i18n;
