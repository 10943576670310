import { takeEvery, fork, put, all, call } from 'redux-saga/effects'

// Login Redux States
import { FORGET_PASSWORD } from './actionTypes'
import { userForgetPasswordSuccess, userForgetPasswordError } from './actions'

//AUTH related methods
import { forgotPassword } from '../../../helpers/authCognito'

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    // const response = yield call(fireBaseBackend.forgetPassword, user.email);
    const response = yield call(forgotPassword, user.email)
    console.log('Forgot password response ', response)

    if (response) {
      yield put(
        userForgetPasswordSuccess(
          'Du ska ha fått en verifieringskod skickat till din email'
        )
      )
    }
  } catch (error) {
    console.log('Ett fel uppstog ', error)

    yield put(userForgetPasswordError(error.message))
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
