export const GREY = '#e6e9eB'
export const GREEN = '#367070'
export const WHINE_RED = '#731c43'
export const PURPLE = '#4b4776'

export const LIGHTER_GREEN = '#69A3A3'
export const LIGHTER_WHINE_RED = '#C06990' //8D365D

export const DARKER_GREY = '#808385'

export const DARKER_GREEN = '#033D3D'
export const DARKER_WHINE_RED = '#400010'
export const DARKER_PURPLE = '#181443'
export const BLUE = "#2222AA"
export const REDISH = "#ba2929aa"
export const NEON_BLUE ="#44cccc"
export const NEW_GREEN = "#648d6d"
export const BRUNISH = "#9f7542"
export const PURPLUSH = "#9929baaa"
