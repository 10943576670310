import React from 'react'
import { Redirect } from 'react-router-dom'

// Pages Component

// Authentication related pages
import Login from '../pages/Authentication/Login'
import ForceChangePassword from '../pages/Authentication/ForceChangePassword'
import ForgetPwd from '../pages/Authentication/FogetPassword'

import Dashboard from '../pages/Dashboard'

const authProtectedRoutes = [
  { path: '/dashboard', component: Dashboard },
  { path: '/', exact: true, component: () => <Redirect to='/dashboard' /> },
]

const publicRoutes = [
  { path: '/new-password', component: ForceChangePassword },

  { path: '/login', component: Login },
  { path: '/forget-password', component: ForgetPwd },
  // { path: '/pages-register', component: Register },

  // // Extra Pages
  // { path: '/pages-login-2', component: PagesLogin2 },
  // { path: '/pages-register-2', component: PagesRegister2 },
  // { path: '/pages-recoverpw-2', component: PagesRecoverpw2 },
  // { path: '/pages-lock-screen-2', component: PagesLockScreen2 },

  // { path: '/pages-404', component: Pages404 },
  // { path: '/pages-500', component: Pages500 },
  // { path: '/pages-Maintenance', component: PagesMaintenance },
  // { path: '/pages-comingsoon', component: PagesComingsoon },
]

export { authProtectedRoutes, publicRoutes }
