import React from "react";
import { Card, CardBody } from "reactstrap";
import { useTranslation } from "react-i18next";
export const ProductSideBar = ({ meetingsFilter, customerLogo }) => {
  const { t } = useTranslation();
  return (
    <Card style={{ height: "98.3%" }} className="bg-primary">
      <CardBody>
        <div className="text-center text-white py-4">
          {customerLogo && (
            <div style={{ marginBottom: "2rem", overflow: "hidden" }}>
              <img
                style={{
                  height: "auto",
                  width: "-webkit-fill-available",
                }}
                src={customerLogo}
                alt={t("dashboard.customer_logo")}
                height="64"
              />
            </div>
          )}
          <h5>{meetingsFilter.product.toUpperCase()}</h5>
        </div>
        <div className="text-center text-white py-4"></div>
      </CardBody>
    </Card>
  );
};
