import React from "react";
import ReactApexChart from "react-apexcharts";
import { filter, map } from "lodash-es";
import { formatCompetitionChoice } from "../utils";

import {
  DARKER_GREY,
  LIGHTER_GREEN,
  GREEN,
  PURPLE,
  WHINE_RED,
  LIGHTER_WHINE_RED,
} from "../theme/colors";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
const colors = [
  WHINE_RED,
  LIGHTER_GREEN,
  PURPLE,
  GREEN,
  LIGHTER_WHINE_RED,
  DARKER_GREY,
];

const CompetitorChart = ({ data, reasonKeys, isRx }) => {
  const competitors = [
    ...new Set(
      map(data, (item) => item.competitors && item.competitors).flat()
    ),
  ].reverse();
  const { t } = useTranslation();
  const competitor_values = competitors.map((key) => t(key));
  // const

  const getCorrectChoice = (item) => {
    if (isRx) {
      return [item.competitionChoiceRX];
    }
    return item.competitionChoice;
  };

  const series = map(reasonKeys, (key) => {
    const dataSeries = map(competitors, (competitor) => {
      return filter(data, (item) => {
        return (
          item.competitors &&
          getCorrectChoice(item) &&
          item.competitors.includes(competitor) &&
          getCorrectChoice(item).includes(key)
        );
      }).length;
    });
    return { name: t(`competition_choice.${key}`), data: dataSeries };
  });
  const getSortedSeries = () => {
    const noOther = series.filter((obj) => obj?.name !== "Other");
    const other = series.find((obj) => obj?.name === "Other");
    if (!other) {
      return noOther;
    }
    return [...noOther, other];
  };
  const sortedSeries = getSortedSeries();
  const isEmpty = series.map((item) => item.data).flat().lenght === 0;
  const options = {
    colors: colors,
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "40%",
      },
    },
    grid: {
      borderColor: "#f8f8fa",
      row: {
        colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },

    xaxis: {
      categories: competitor_values,
      labels: {
        formatter: function (val) {
          return val;
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      title: {
        text: undefined,
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    fill: {
      opacity: 1,
    },

    legend: {
      show: true,
      position: "top",
      horizontalAlign: "right",
      offsetX: 40,
      markers: {
        height: 13,
        width: 13,
        radius: 3,
      },
    },
  };

  return (
    <React.Fragment>
      {isEmpty ? (
        <div style={{ height: "300px" }}>
          <Typography>Ingen data att visa än</Typography>
        </div>
      ) : (
        <ReactApexChart
          options={options}
          series={sortedSeries}
          type="bar"
          height="290"
        />
      )}
    </React.Fragment>
  );
};

export default CompetitorChart;
