import React, { useState, memo, useEffect, useCallback } from 'react'
import { get } from 'lodash-es'
import { useApolloClient } from 'react-apollo'
import { useHistory } from 'react-router-dom'
import { logout } from '../helpers/authCognito'

import {
  getCurrentUser,
  postLogin,
  postSignUp,
  postNewPassword,
  postChangePassword,
} from '../helpers/authCognito'

// signOut
const signOutAndResetApollo = async (client) => {
  try {
    if (client) {
      await client.clearStore()
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
  } finally {
    localStorage.removeItem('apollo-cache-persist')
  }
}

export const UserContext = React.createContext({})

export const UserProvider = memo(({ children, history }) => {
  // const history = useHistory()
  const client = useApolloClient()
  const [user, setUser] = useState({
    isUserLoaded: false,
    loading: false,
  })

  useEffect(() => {
    const checkAuth = async () => {
      const cognitoUser = await getCurrentUser()

      setUser({
        username: get(cognitoUser, 'username'),
        region: get(cognitoUser, ['attributes', 'custom:region']),
        email: get(cognitoUser, ['attributes', 'email']),
        isUserLoaded: true,
        loading: false,
        cognitoUser,
        session: cognitoUser ? cognitoUser.signInUserSession : null,
      })
    }
    checkAuth()
  }, [])

  const signIn = async (email, password) => {
    setUser({ ...user, loading: true })

    try {
      const cognitoUser = await postLogin(email, password)

      const newUser = {
        cognitoUser,
        username: cognitoUser.username,
        isUserLoaded: true,
        loading: false,
        session: cognitoUser.signInUserSession,
      }

      setUser(newUser)

      return newUser
    } catch (e) {
      setUser({ ...user, loading: false })
      throw e
    }
  }

  const changePassword = async (user, email, oldPassword, password) => {
    setUser({ ...user, loading: true })

    try {
      const cognitoUser = await postChangePassword(user, oldPassword, password)

      const newUser = {
        cognitoUser,
        username: cognitoUser.username,
        isUserLoaded: true,
        loading: false,
        session: cognitoUser.signInUserSession,
      }

      setUser(newUser)

      return newUser
    } catch (e) {
      setUser({ ...user, loading: false })
      throw e
    }
  }

  const newPassword = async (user, oldPassword, password) => {
    try {
      const cognitoUser = await postNewPassword(user, oldPassword, password)

      const newUser = {
        cognitoUser,
        username: cognitoUser.username,
        isUserLoaded: true,
        loading: false,
        session: cognitoUser.signInUserSession,
      }

      setUser(newUser)

      return newUser
    } catch (e) {
      console.log('Getting in Error...', e)
      setUser({ ...user, loading: false })
      throw e
    }
  }

  const signUp = async (email, password) => {
    const { user: cognitoUser } = await postSignUp(email, password)
    const newUser = {
      cognitoUser,
      username: cognitoUser.getUsername(),
      isUserLoaded: true,
      loading: false,
      // session: cognitoUser.getSession(),
    }

    setUser(newUser)
    return newUser
  }

  const signOut = useCallback(async () => {
    const { cognitoUser } = user
    if (cognitoUser) {
      cognitoUser.signOut()
      setUser({ ...user, cognitoUser: null, session: null })
    }
    console.log('logging out signing out')
    logout()
    signOutAndResetApollo(client)
    history.push('/')
  }, [
    user,
    // client,
    history,
  ])

  return (
    <UserContext.Provider
      value={{ user, signIn, signUp, signOut, newPassword, changePassword }}
    >
      {/* <ApolloProvider client={client}> */}
      {children}
      {/* </ApolloProvider> */}
    </UserContext.Provider>
  )
})
