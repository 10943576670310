import React, { useState } from 'react'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Chip,
  Checkbox,
  Typography,
} from '@material-ui/core'
import styled from '@emotion/styled'
import { PURPLE } from '../theme/colors'
import { useTranslation } from 'react-i18next'

const StyledInputLabel = styled(InputLabel)`
  margin: -1rem 0 0 0.8rem;
`
const MySelect = styled(Select)`
  && {
    height: 4rem;
  }
`

const StyledCheckbox = styled(Checkbox)`
  &.MuiCheckbox-colorSecondary.Mui-checked {
    color: ${PURPLE};
  }
`

const StyledSelect = ({
  values,
  label,
  name,
  selected,
  onChange,
  single,
  formatFunction,
}) => {
  const { t } = useTranslation()
  return (
    <FormControl fullWidth>
      <StyledInputLabel shrink htmlFor={name}>
        {label}
      </StyledInputLabel>
      <MySelect
        name={name}
        multiple={single ? !single : true}
        value={selected}
        onChange={onChange}
        displayEmpty
        input={<OutlinedInput />}
        renderValue={(selectedValues) => {
          if (!single) {
            if (selectedValues.length === 0) {
              return <Typography>{t("dashboard.no_filter")}</Typography>
            }
            return selectedValues.length === values.length ? (
              <Typography>{t("dashboard.all_selected")}</Typography>
            ) : (
              selectedValues.map((value) => (
                <Chip
                  key={value}
                  label={formatFunction ? formatFunction(value) : value}
                />
              ))
            )
          }
          return <Typography>{selectedValues}</Typography>
        }}
      >
        {values.map((name) => (
          <MenuItem key={name} value={name}>
            {!single && (
              <StyledCheckbox
                checked={selected ? selected.includes(name) : false}
              />
            )}
            <Typography>
              {formatFunction ? formatFunction(name) : name}
            </Typography>
          </MenuItem>
        ))}
      </MySelect>
    </FormControl>
  )
}

export default StyledSelect

// const handleChange = (event) => {
//   setPersonName(event.target.value)
// }

// ;<FormControl>
//   <InputLabel>Chip</InputLabel>
//   <Select
//     labelId='demo-mutiple-chip-label'
//     id='demo-mutiple-chip'
//     multiple
//     value={personName}
//     onChange={handleChange}
//     input={<OutlinedInput id='select-multiple-chip' />}
//     renderValue={(selected) => (
//       <div className={classes.chips}>
//         {selected.map((value) => (
//           <Chip key={value} label={value} className={classes.chip} />
//         ))}
//       </div>
//     )}
//     MenuProps={MenuProps}
//   >
//     {names.map((name) => (
//       <MenuItem
//         key={name}
//         value={name}
//         style={getStyles(name, personName, theme)}
//       >
//         {name}
//       </MenuItem>
//     ))}
//   </Select>
// </FormControl>
