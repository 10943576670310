import React from "react";
import ReactEcharts from "echarts-for-react";
import echarts from "echarts";
import { getBrandknowledgeKey } from "../utils";
import { filter, reduce } from "lodash-es";
import {
  DARKER_GREY,
  GREEN,
  WHINE_RED,
  PURPLE,
  LIGHTER_GREEN,
  LIGHTER_WHINE_RED,
  DARKER_GREEN,
  DARKER_PURPLE,
  DARKER_WHINE_RED,
  GREY,
  BLUE,
  REDISH,
  NEON_BLUE,
  NEW_GREEN,
  BRUNISH,
  PURPLUSH,
} from "../theme/colors";
import { useTranslation } from "react-i18next";
import { es, is } from "date-fns/locale";
const colorObj = {
  primary: {
    color: [GREEN, DARKER_GREEN],
    shadowColor: GREEN,
    symbolOffset: [-20, 0],
  },
  recommending: {
    color: [LIGHTER_GREEN, GREEN],
    shadowColor: LIGHTER_GREEN,
    symbolOffset: [-10, 0],
  },
  testing: {
    color: [DARKER_GREY, GREY],
    shadowColor: DARKER_GREY,
    symbolOffset: [0, 0],
  },
  understands: {
    color: [PURPLE, DARKER_PURPLE],
    shadowColor: PURPLE,
    symbolOffset: [10, 0],
  },
  knowledge: {
    color: [LIGHTER_WHINE_RED, WHINE_RED],
    shadowColor: LIGHTER_WHINE_RED,
    symbolOffset: [20, 0],
  },
  none: {
    color: [WHINE_RED, DARKER_WHINE_RED],
    shadowColor: WHINE_RED,
    symbolOffset: [30, 0],
  },
  ej_intresserad_nu: {
    color: [BLUE, DARKER_WHINE_RED],
    shadowColor: WHINE_RED,
    symbolOffset: [30, 0],
  },
  visst_intresse: {
    color: [REDISH, DARKER_WHINE_RED],
    shadowColor: WHINE_RED,
    symbolOffset: [30, 0],
  },
  hogt_intresse: {
    color: [NEON_BLUE, DARKER_WHINE_RED],
    shadowColor: WHINE_RED,
    symbolOffset: [30, 0],
  },
  ska_bestalla: {
    color: [NEW_GREEN, DARKER_WHINE_RED],
    shadowColor: WHINE_RED,
    symbolOffset: [30, 0],
  },
  lagt_bestallning: {
    color: [BRUNISH, DARKER_WHINE_RED],
    shadowColor: WHINE_RED,
    symbolOffset: [30, 0],
  },
  kan_ej_bestalla: {
    color: [PURPLUSH, DARKER_WHINE_RED],
    shadowColor: WHINE_RED,
    symbolOffset: [30, 0],
  },
  expedites_regularly: {
    color: [DARKER_GREY, GREY],
    shadowColor: DARKER_GREY,
    symbolOffset: [0, 0],
  },
  expedites_sometimes: {
    color: [PURPLE, DARKER_PURPLE],
    shadowColor: PURPLE,
    symbolOffset: [10, 0],
  },
  no_knowledge: {
    color: [WHINE_RED, DARKER_WHINE_RED],
    shadowColor: WHINE_RED,
    symbolOffset: [30, 0],
  },
};

const generateSeriesPoint = (key, name, data, N) => {
  return {
    name: name,
    data: data,
    symbolSize: function (data) {
      const size = 1 + (data / N) * 100;
      const symbSize = Math.sqrt(Math.log2(size)) * 15;
      return !data ? 0 : symbSize;
    },
    type: "scatter",
    symbolOffset: colorObj[key].symbolOffset,
    itemStyle: {
      opacity: 0.2,
      normal: {
        // shadowBlur: 10,
        // shadowColor: colorObj[key].shadowColor, //'rgba(2, 164, 153, 0.5)',
        // shadowOffsetY: 5,
        color: new echarts.graphic.RadialGradient(0.4, 0.3, 1, [
          {
            offset: 0,
            color: colorObj[key].color[0],
          },
          // {
          //   offset: 1,
          //   color: colorObj[key].color[1],
          // },
        ]),
      },
    },
    // center: colorObj[key].centerOffset,
    label: {
      emphasis: {
        show: true,
        // formatter: formatBrandKnowledge(key),
        formatter: function (param) {
          return param.data;
        },
        position: "top",
      },
    },
  };
};

const ProductKnowledgeChart = ({ isRx, isVet, data, brandKnowledgeKeys }) => {
  const { t } = useTranslation();

  const getCorrectBrandKnowledge = (item) => {
    return isRx ? item.brandKnowledgeRX : item.brandKnowledge;
  };

  const meetings = reduce(
    brandKnowledgeKeys,
    (obj, prKnowledge) => {
      const meetingData = ["1", "2", "3", "4"].map((meetingNr) => {
        const meetingData = filter(data, (item) => {
          return (
            item.meetingNumber &&
            getCorrectBrandKnowledge(item) &&
            item.meetingNumber.toString() === meetingNr &&
            prKnowledge === getCorrectBrandKnowledge(item)
          );
        });

        return meetingData.length;
      });
      return { ...obj, [prKnowledge]: meetingData };
    },
    {}
  );

  const valueArray = Object.values(meetings).flat();
  const calculatedN = Math.max(...valueArray);

  const series = brandKnowledgeKeys.map(
    (key) =>
      generateSeriesPoint(
        key,
        t(`brand_knowledge.${getBrandknowledgeKey(key, isVet)}`),
        meetings[key],
        calculatedN
      )
    // {t(`brand_knowledge.${data.pharmacyName}`)}
  );
  const getOption = () => {
    return {
      toolbox: {
        show: false,
      },
      legend: {
        align: "right",
        right: 0,
        orient: "vertical",
        top: 0,
        data: brandKnowledgeKeys.map((key) =>
          t(`brand_knowledge.${getBrandknowledgeKey(key, isVet)}`)
        ),
      },
      grid: {
        left: "5%",
        bottom: "10%",
        top: "15%",
        right: "20%",
        borderWidth: 0,
        backgroundColor: "rgba(0,0,0,0)",
        borderColor: "rgba(0,0,0,0)",
      },
      xAxis: {
        splitLine: {
          lineStyle: {
            type: "dashed",
          },
        },
        axisLine: {
          lineStyle: {
            color: "#74788d",
          },
        },
        type: "category",
        data: [t("meeting.1"), t("meeting.2"), t("meeting.3"), t("meeting.4")],
      },
      yAxis: {
        boundaryGap: true,
        name: "Antal",

        scale: true,
        axisLine: {
          lineStyle: {
            color: "#74788d",
          },
        },
      },
      series: series,
      color: ["#00bbaa"],
    };
  };

  return (
    <React.Fragment>
      <ReactEcharts option={getOption()} />
    </React.Fragment>
  );
};
export default ProductKnowledgeChart;
