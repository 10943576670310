import React, { Component, useContext } from 'react'
import { Link } from 'react-router-dom'

import logolightImg from '../../assets/images/vitPNG.png'

import { useHistory } from 'react-router-dom'
import { IconButton } from '@material-ui/core'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import styled from '@emotion/styled'
import { UserContext } from '../../context'
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`
const LogoutPadding = styled.div`
  margin-right: 1rem;
  height: 100%;
`

const TopBar = () => {
  const { signOut } = useContext(UserContext)
  const history = useHistory()
  return (
    <React.Fragment>
      <header id='page-topbar'>
        <div className='navbar-header'>
          <Wrapper>
            <div className='d-flex'>
              <div className='navbar-brand-box'>
                <Link to='/dashboard' className='logo logo-light'>
                  <span className='logo-sm'>
                    <img src={logolightImg} alt='' height='44' />
                  </span>
                  <span className='logo-lg'>
                    <img src={logolightImg} alt='' height='44' />
                  </span>
                </Link>
              </div>
            </div>
            <LogoutPadding>
              <IconButton
                onClick={() => {
                  signOut()
                  history.push('/Login')
                }}
              >
                <ExitToAppIcon style={{ fill: 'white' }} />
              </IconButton>
            </LogoutPadding>
          </Wrapper>
        </div>
      </header>
    </React.Fragment>
  )
}

export default TopBar
