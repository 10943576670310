import React from 'react'
import { FormControl, InputLabel } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import styled from '@emotion/styled'
const StyledInputLabel = styled(InputLabel)`
  margin: -1rem 0 0 0.8rem;
`
const StyledPicker = styled(KeyboardDatePicker)`
  && {
    margin-top: 0;
  }
  .MuiOutlinedInput-adornedEnd {
    height: 4rem !important;
  }
`

const StyledKeyboardDatePicker = ({ onChange, value, name, label }) => {
  return (
    <FormControl fullWidth>
      <StyledInputLabel shrink htmlFor={name} id='demo-mutiple-name-label'>
        {label}
      </StyledInputLabel>
      <StyledPicker
        inputVariant='outlined'
        disableToolbar
        variant='inline'
        format='MM/dd/yyyy'
        margin='normal'
        id='date-picker-inline'
        // label='Date picker inline'
        value={value}
        onChange={onChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </FormControl>
  )
}
export default StyledKeyboardDatePicker
