import React from "react";
import ReactEcharts from "echarts-for-react";
import { formatShelfAppearance } from "../utils";
import { filter, map, values } from "lodash-es";
import { generateDataPoint } from "../utils";
import {
  DARKER_GREY,
  GREEN,
  WHINE_RED,
  PURPLE,
  LIGHTER_GREEN,
  LIGHTER_WHINE_RED,
} from "../theme/colors";
import { useTranslation } from "react-i18next";
const ShelfAppearanceChart = ({ data }) => {
  const { t } = useTranslation();
  const appereance_keys = [
    "asExpected",
    "didNotExist",
    "notRefilled",
    "messy",
    "campaignMissing",
    "limited_visibility",
  ];
  const data_values = appereance_keys.map((key) =>
    t(`shelf_appearance.${key}`)
  );
  const plotData = map(appereance_keys, (key) =>
    generateDataPoint(data, "shelfAppearance", key, (key) =>
      t(`shelf_appearance.${key}`)
    )
  );
  const getOption = () => {
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b}: {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        icon: "roundRect",
        itemWidth: 13,
        itemHeight: 13,
        x: "right",
        data: data_values,
        textStyle: {
          color: ["#74788d"],
        },
      },
      color: [
        GREEN,
        LIGHTER_GREEN,
        PURPLE,
        DARKER_GREY,
        WHINE_RED,
        LIGHTER_WHINE_RED,
      ],

      series: [
        {
          name: t("dashboard.shelf_appearance_alternative"),
          type: "pie",
          radius: ["45%", "55%"],
          center: ["50%", "65%"],

          avoidLabelOverlap: false,
          label: {
            normal: {
              show: false,
              position: "center",
            },
            emphasis: {
              show: true,
              textStyle: {
                fontSize: "18",
                fontWeight: "bold",
              },
            },
          },
          labelLine: {
            normal: {
              show: false,
            },
          },
          data: plotData,
        },
      ],
    };
  };
  return (
    <React.Fragment>
      {/* {plotData.map((item) => item.value).filter((value) => value !== 0)
        .length > 0 ? ( */}
      <ReactEcharts style={{ height: "350px" }} option={getOption()} />
      {/* ) : (
        <Typography>Ingen data att visas än</Typography>
      )} */}
    </React.Fragment>
  );
};
export default ShelfAppearanceChart;
