
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link';
import Auth from '@aws-amplify/auth';
import getEnvConfig from './env.config';

const getAWSApolloClient = () => {
  const url = getEnvConfig().apolloUri;

  const httpLink = createHttpLink({
    uri: url,
  });

  const authLink = createAuthLink({
    url,
    region: 'eu-west-1',
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () =>
        (await Auth.currentSession()).getIdToken().getJwtToken(),
    },
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    disableOffline: true,
  });

  return client;
};

export default getAWSApolloClient;