import React, { Component } from 'react'
import { Row, Col, Card, CardBody, Alert } from 'reactstrap'

// Redux
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation'

// action
import { userForgetPassword } from '../../store/actions'

// import images
import logoSm from '../../assets/images/svartPNG.png'

import { Auth } from '@aws-amplify/auth'

const ForgetPasswordPage = (props) => {
  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    props.userForgetPassword(values, props.history)
  }

  const handleValidNewPassword = (event, values) => {
    //props.confirmNewPassword(values.email, values.code, values.newPassword)
    // Auth.forgotPasswordSubmit(username, code, new_password)
    // .then(data => console.log(data))
    // .catch(err => console.log(err));
  }
  return (
    <React.Fragment>
      <div className='home-btn d-none d-sm-block'>
        <Link to='/' className='text-dark'>
          <i className='fas fa-home h2'></i>
        </Link>
      </div>
      <div className='account-pages my-5 pt-5'>
        <div className='container'>
          <Row className='justify-content-center'>
            <Col md={8} lg={6} xl={5}>
              <div className='position-relative'>
                <Card className='overflow-hidden'>
                  <div className='bg-primary'>
                    <div className='text-primary text-center p-4'>
                      <h5 className='text-white font-size-20'>
                        Glömt lösenord
                      </h5>
                      <p className='text-white-50'>
                        Skriv in din email i fältet under, så skickar vi en
                        verfieringskod till din email. Skriv sedan in
                        verifieringskoden och ditt nya lösenord.
                      </p>
                      <Link to='/' className='logo logo-admin'>
                        <img
                          style={{
                            position: 'relative',
                            left: '2px',
                            bottom: '2px',
                          }}
                          src={logoSm}
                          height='34'
                          alt='logo'
                        />
                      </Link>
                    </div>
                  </div>

                  <CardBody className='p-4'>
                    <div className='p-3'>
                      {props.forgetError && props.forgetError ? (
                        <Alert color='danger' style={{ marginTop: '13px' }}>
                          {props.forgetError}
                        </Alert>
                      ) : null}
                      {props.forgetSuccessMsg ? (
                        <Alert color='success' style={{ marginTop: '13px' }}>
                          {props.forgetSuccessMsg}
                        </Alert>
                      ) : null}

                      <AvForm
                        className='form-horizontal mt-4'
                        onValidSubmit={
                          !props.forgetSuccessMsg
                            ? handleValidSubmit
                            : handleValidNewPassword
                        }
                      >
                        <div className='form-group'>
                          <AvField
                            name='email'
                            label='Email'
                            className='form-control'
                            placeholder='Email'
                            type='email'
                            validate={{
                              required: {
                                value: true,
                                errorMessage: 'Inte giltig email',
                              },
                              email: {
                                value: true,
                                errorMessage: 'Inte giltig email',
                              },
                            }}
                          />
                          {props.forgetSuccessMsg && (
                            <div className='form-group'>
                              <AvField
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: 'Inte giltig kod',
                                  },
                                }}
                                name='code'
                                label='Verifieringskod'
                                className='form-control'
                                placeholder='Verifieringskod'
                                required={props.forgetSuccessMsg ? true : false}
                              />
                              <AvField
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: 'Inte giltigt lösenord',
                                  },
                                  pattern: {
                                    // ^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]
                                    // (?=.*[a-z])(?=.*[A-Z])(?=.*)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]
                                    value:
                                      '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[?!&%\\[\\]@{}^])',
                                    errorMessage:
                                      'Ditt lösenord måste ha stora och små bokstäver minst en symbol och nummer',
                                  },
                                  minLength: {
                                    value: 6,
                                    errorMessage:
                                      'Ditt lösenord ska vara mellan 6 and 20 karaktärer',
                                  },
                                  maxLength: {
                                    value: 20,
                                    errorMessage:
                                      'Ditt lösenord ska vara mellan 6 and 20 karaktärer',
                                  },
                                }}
                                name='newPassword'
                                label='Nytt lösenord'
                                type='password'
                                className='form-control'
                                placeholder='Nytt lösenord'
                                required={props.forgetSuccessMsg ? true : false}
                              />
                            </div>
                          )}
                        </div>
                        <Row className='form-group'>
                          <Col className='text-right'>
                            <button
                              className='btn btn-primary w-md waves-effect waves-light'
                              type='submit'
                            >
                              {!props.forgetSuccessMsg
                                ? 'Begär nytt lösenord'
                                : 'Bekräfta nytt lösenord'}
                            </button>
                          </Col>
                        </Row>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStatetoProps = (state) => {
  console.log(state.forgetSuccessMsg)
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword
  return { forgetError, forgetSuccessMsg }
}

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(ForgetPasswordPage)
)
